<template>
  <div class="client-myTab2">
    <div class="client-header">
      <el-button type="primary" @click="add">{{lang.addTheme}}</el-button>
    </div>
    <div class="client-main">
      <els-table
        ref="elsTab"
        style="height: 500px"
        :column="column"
        :onload="onload"
        :showPagination="false"
        :menuWidth="'120px'"
        idKey="key"
      >
        <template v-slot:menu="{ row }">
          <span
            @click="editView(row)"
            :style="{ color: row.type === '0' ? '#c7c7c7' : '#409eff' }"
          >
            {{$t('d.edit')}}
          </span>
          <span class="client-line"></span>
          <span
            @click="delView(row)"
            :style="{ color: row.type === '0' ? '#c7c7c7' : 'red' }"
          >
            {{$t('d.delete')}}
          </span>
        </template>
      </els-table>
      <div class="footer">
        <el-button @click="close">{{$t('d.close')}}</el-button>
      </div>
      <title-drawer
        ref="titleDialog"
        :data="titleData"
        :elsData="elsform"
        :rowData="rowD"
        @add="addTitle"
        show
      ></title-drawer>
    </div>
  </div>
</template>
 <script>
import elsTable from "../../../components/els-table/main.vue";
import titleDrawer from "../clientType/title-drawer.vue";
import { mapState } from "vuex";
import { getDict } from "@/api/com/iot-dict.js";
import mixinsI18n from "@/mixins/i18n.js"
import {
  getDevicesByIds,
  saveMqttTopic,
  deleteMqttTopic,
} from "@/api/manager/client-type-manage.js";
export default {
  name: "client-tab2",
  mixins: [mixinsI18n],
  components: { elsTable, titleDrawer },
  props: {
    lastDicts: Object,
  },
  data: () => {
    return {
      rowD: {},
      titleData: {
        title: "",
        tSign: 1,
      },
      dicts: {
        mqttOperationAutherity: [],
        mqttTopicType: [],
      },
      subId: "",
      PAGE_NAME: "clientTypePage",
    };
  },
  computed: {
    ...mapState("clientType", {
      row(state) {
        return state.row;
      },
      column() {
        const dicts = this.lastDicts;
        const tab = [
          { prop: "topicLable", label: this.lang.themeTab },
          { prop: "topic", label: this.lang.theme },
          {
            prop: "operationAuthority",
            label: this.lang.opeAuth,
            dict: dicts.mqttOperationAutherity,
          },
          { prop: "describe", label: this.lang.describe },
        ];
        return tab;
      },
      elsform() {
        const dicts = this.lastDicts;
        const tab = [
          {
            tag: "div",
            title: this.lang.basicPro,
            cls: [
              {
                label: this.lang.themeTab,
                tag: "el-input",
                prop: "topicLable",
                required: true,
                rules: [
                  {
                    pattern: /^\w{0,20}$/,
                    message: this.lang.themeTabMsg,
                    trigger: "blur",
                  },
                ],
              },
              {
                label: this.lang.theme,
                tag: "el-input",
                prop: "topic",
                required: true,
                type: "textarea",
                rules: [
                  {
                    // pattern: /^\w{0,200}$/,
                    pattern: /^\S{1,200}$/, ///^[u4e00-u9fa5/+#$\w]+$/
                    message: this.lang.themeMsg,
                    trigger: "blur",
                  },
                ],
              },
              {
                tag: "el-select",
                label: this.lang.opeAuth,
                required: true,
                prop: "operationAuthority",
                placeholder: this.lang.pleaseSelect,
                cls: dicts.mqttOperationAutherity,
                trigger: "change",
              },
              {
                label: this.lang.describe,
                tag: "el-input",
                prop: "describe",
                type: "textarea",
                rules: [
                  {
                    max: 50,
                    message: this.lang.max50,
                    trigger: "blur",
                  },
                ],
              },
            ],
          },
        ];
        return tab;
      },
    }),
  },
  methods: {
    async onload() {
      const d = {
        deviceTypeId: this.row.id || this.row.id.id,
      };
      try {
        let data = await getDevicesByIds({ ...d });
        // data.forEach((item) => {
        //   if (item.type === "0") {
        //     item.operationAuthority =
        //       item.operationAuthority.split("_")[1] === 0
        //         ? "mqttOperationAutherity_0"
        //         : "mqttOperationAutherity_1";
        //   }
        // });
        this.total = data.length;
        return { list: data, total: this.total };
      } catch (e) {
        console.log(e);
      }
      return {};
    },
    async addTitle(form) {
      const d = {
        // id: !!this.subId ? this.subId : "",
        deviceTypeId: this.row.id || this.row.id.id,
        topic: form.topic,
        type: "1", //添加 type为1嘛
        topicLable: form.topicLable,
        operationAuthority: form.operationAuthority,
        describe: form.describe,
      };
      if (!!this.subId) {
        d.id = this.subId;
      }
      await saveMqttTopic({ ...d })
        .then((res) => {
          if (res) {
            this.subId = "";
            this.$refs.titleDialog.dialogTableVisible = false;
            this.$refs.elsTab.apiOnload();
          }
        })
        .catch((err) => (this.isSure = false));
    },
    add() {
      this.$refs.titleDialog.dialogTableVisible = true;
      this.titleData.title = this.lang.addTheme;
      this.rowD = {};
    },
    editView(edit) {
      if (edit.type === "0") return false; //系统默认，查看删除不可操作
      this.subId = edit.id;
      this.$refs.titleDialog.dialogTableVisible = true;
      this.titleData.title = this.lang.modifyTheme;
      this.$nextTick(() => {
        setTimeout(() => {
          this.rowD = { ...edit };
        }, 0);
      });
    },
    delView(row) {
      if (row.type === "0") return false;
      if (row) {
        this.$confirm(this.lang.delMsg, this.lang.tips, {
          confirmButtonText: this.lang.confirm,
          cancelButtonText: this.lang.cancel,
          type: "warning",
        })
          .then(() => {
            deleteMqttTopic({ id: row.id }).then((res) => {
              this.$message({
                message: this.lang.delSuccess,
                type: "success",
              });
              this.$refs.elsTab.apiOnload();
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style scoped lang='scss'>
.client-myTab2 {
  .client-main {
    margin-top: 20px;
    .footer {
      text-align: right;
      margin-top: 20px;
    }
    .client-line {
      margin: 0px 8px;
      display: inline-block;
      width: 1px;
      height: 12px;
      background: #ddd;
    }
  }
}
</style>
